
























































































































































import {Vue,Component} from 'vue-property-decorator'
import {Largo} from '@/entidades/Maestro/Ktb/Largo'
import {Espesor} from '@/entidades/Maestro/Ktb/Espesor';
import { TurnoQuery } from '@/entidades/KTB/RegistroInformacion/Turno';
import {State,Action} from 'vuex-class';
import { Planta } from '@/entidades/Maestro/Planta';
import {ProduccionQuery,ProduccionListado,ObtenerProduccion, GenerarExcel,EliminarPallet,ImprimirPallet} from '@/entidades/KTB/Produccion/ProduccionQuery';
import moment from 'moment';
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
import {ValidarApertura, TurnoAperturaResponse} from '@/entidades/KTB/Turno/Index';
import { ObtenerConsumoActivo } from '@/entidades/KTB/Consumo/ConsumoEntity';
import PrinterPalletRequest from '@/entidades/KTB/Produccion/PrinterPalletRequest';
@Component({
    name : 'ProduccionView',
    components:{
        'produccionAdd' : () => import('@/components/KTB/Operacion/Produccion/ProduccionAdd.vue')
    }
})
export default class ProduccionView extends Vue
{
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('espesor',{namespace: 'maestroKtb'}) listadoEspesor!:Array<Espesor>;
    @State('largo',{namespace: 'maestroKtb'}) listadoLargo!:Array<Largo>;
    @State('turno',{namespace: 'maestroKtb'}) listadoTurno!:Array<TurnoQuery>;
    modalEliminar:boolean=false;
    menu:boolean =false;
    menu2:boolean =false;
    motivoEliminacion:string="";
    desde:string ="";
    hasta:string ="";
    largoId:number =0;
    espesorId:number =0;
    turnoId:Array<number> =[];
    modal:boolean=false;
    componenteName:string="";
    listadoProduccion:Array<ProduccionListado>=[];
    itemProduccion:ProduccionListado| undefined = undefined;
    aperturaValidada:boolean =false;
    openTurno:boolean=false;
    turnoAsignado?:TurnoAperturaResponse;
    consumoActivo:boolean = false;
    totalProduccion:number =0;
     modalConfirmacion:boolean=false;
    messageConfirmacion:string="";
    componentDialog="";
    propsEnviar:any={};
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"# Pallet",sortable:false,value:'palletId'},
            {text:"Hacienda",sortable:false,value:'haciendaName'},
            {text:"Fecha",sortable:false,value:'fecha'},
            {text:"Turno",sortable:false,value:'turnoName'},
            {text:"Largo",sortable:false,value:'largoName'},
            {text:"Espesor",sortable:true,value:'espesorName'},
            {text:"# Plantillas",sortable:false,value:'numPlantilla'},
            {text:"Volumen",sortable:true,value:'volumen'},
            {text:"Mesa",sortable:true,value:'mesaName'},
            {text:"Linea",sortable:false,value:'lineaName'},
            {text:"Trabajador",sortable:false,value:'empleadoName'},
            {text:"Estado",sortable:false,value:'estadoDescripcion'},
            {text:"Imprimir",sortable:false,value:'imprimir'}   ,
            {text:"Eliminar",sortable:false,value:'eliminar'}
    ];
     closeDesde(fecha:string)
     {
        this.menu = false;
        this.desde = fecha;

     }
     closeHasta(fecha:string)
     {
        this.hasta = fecha;
        this.menu2 = false;
     }

    limpiar()
    {
        this.modal = false;
        this.componenteName = "";

    }

    abrirModal()
    {
        this.componenteName = "produccionAdd";
        this.modal = true;
    }

    abrirlModalAperturaTurno()
    {
        this.componenteName = "turnoApertura";
        this.openTurno = true;
        this.modal = true;
    }
    eliminarPalletModal(item:ProduccionListado)
    {
        //
        this.itemProduccion = item;
        this.messageConfirmacion = `Estas Seguro de Eliminar el pallet ${item.palletId}`;
        this.modalEliminar = true;
    }
    eliminarPallet()
    {

        // this.componentDialog = "ConfirmacionDialog";
        // this.modalConfirmacion = true;

        // this.propsEnviar ={'open' : this.modalConfirmacion,'message' :this.messageConfirmacion,
        //     'methodDialog':this.confirmacionDialogResponse,'objeto':item,
        //     'tipo': 2};
    }
    async imprimirPallet(item:ProduccionListado)
    {
      this.changeLoading(new Loading(true,"Impression de Pallet...."));
      try
      {
        var rsp = await ImprimirPallet(new PrinterPalletRequest(item.id));
        if(rsp.isSuccess)
          this.changeAlerta(new Alerta("Impresion correcta",true,"primary",3000))
        else
        this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000))
      }
      catch(error)
      {
        console.log(error);
      }
      finally
      {
        this.changeLoading(new Loading(false,"Impression de Pallet...."));
      }

    }
     async confirmacionDialogResponse()
    {

        try
        {
            if(this.itemProduccion != undefined)
            {
                this.changeLoading(new Loading(true,"Eliminando Pallet"));
                this.itemProduccion.estadoDescripcion = this.motivoEliminacion;
                this.itemProduccion.usuarioId = this.usuarioId;
                var response = await EliminarPallet(this.itemProduccion);
                if(response.isSuccess)
                {
                    this.changeAlerta(new Alerta(response.isMessage,true,"primary",3000));
                    this.modalEliminar = false;
                    return;
                }
                this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
                this.changeLoading(new Loading(false,""));
        }

    }

    async ObtenerProduc()
    {
        var query:ProduccionQuery = new ProduccionQuery();
        query.largoId = this.largoId;
        query.espesorId = this.espesorId;
        query.turnoId = this.turnoId;
        query.desde = this.desde;
        query.hasta = this.hasta;
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion...."));
            var rsp = await ObtenerProduccion(query);
            if(rsp.isSuccess == true)
            {

                this.listadoProduccion = rsp.isResult;

                let inicio:number=0;
               this.totalProduccion =  this.listadoProduccion.reduce(function(total,currentValue){
                return Number(total + currentValue.volumen)
            },inicio)

            }
            else
            {
                this.listadoProduccion = [];
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    async exportExcel()
    {
        try
        {

            this.changeLoading(new Loading(true,"Generando excel...."));
            var query:ProduccionQuery = new ProduccionQuery();
            query.largoId = this.largoId;
            query.espesorId = this.espesorId;
            query.turnoId = this.turnoId;
            query.desde = this.desde;
            query.hasta = this.hasta;
            var rsp = await GenerarExcel(query);
            let blob = new Blob([rsp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            url = window.URL.createObjectURL(blob);
            window.open(url);
            //var fileURL = window.URL.createObjectURL(new Blob([rsp]));
            //var fileLink = document.createElement('a');
            //fileLink.href = fileURL;
            //         fileLink.setAttribute('download', 'file.xlsx');
            //         document.body.appendChild(fileLink);
            //         fileLink.click();

        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",3000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }



    get habilitarProduccion()
    {
        if(this.consumoActivo == true && this.aperturaValidada == true)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
    get propiedades()
    {
        if(this.componenteName == "produccionAdd")
        {
            return {'listadoEspesor' : this.listadoEspesor, 'listadoLargo' : this.listadoLargo,
                    'plantaId' : this.plantas[0].plantaId,
                    'aperturaTurno' : this.turnoAsignado,
                    'procedencia' : 'TALLY',
                    'recepcionId' : 0
            }
        }
        else if(this.componenteName == "turnoApertura")
        {
            return {'open' : this.openTurno};
        }
        else if(this.componentDialog == "ConfirmacionDialog")
        {

            return {'open' : this.modalConfirmacion,'message' :this.messageConfirmacion,
            'methodDialog':this.confirmacionDialogResponse}
        }
    }

    async mounted()
    {
       this.desde = moment(new Date()).format("YYYY-MM-DD");
       this.hasta = moment(new Date()).format("YYYY-MM-DD");
       var rsp = await ValidarApertura(this.plantas[0].plantaId,1);
       this.aperturaValidada = rsp.isSuccess;
       this.turnoAsignado = rsp.isResult;

       var consumo = await ObtenerConsumoActivo(this.plantas[0].plantaId);
       this.consumoActivo = consumo.isSuccess;
    }
}
